import React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import '../components/about.css'
import Allabout from '../components/Allabout'

const Allabouts = ({
  data: {
    allStrapiAbouts: { nodes: aboutlist },
  }, location
}) => {
  return (
    <Layout location={location} crumbLabel="About">
      <div className="container">
        <div className="box ">
          {aboutlist.map((aboutlists, index) => {
            return <Allabout key={index} {...aboutlists} />
          })}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allStrapiAbouts {
      nodes {
        id
        aboutTitle
        about_content {
          content_desc
          id
        }
        about_font_family {
          family
        }
        about_font_style {
          size
        }
        about_font_weight {
          weight
        }
        about_color {
          color
        }
        bg_color {
          color
        }
        content_align
        heading_font_color {
          color
        }
        heading_font_family {
          family
        }
        heading_font_size {
          size
        }
        heading_font_weight {
          weight
        }
      }
    }
  }
`
export default Allabouts
