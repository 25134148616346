import React from 'react'
import './about.css'

const Aboutlist = ({
  aboutTitle,
  about_content,
  about_font_family,
  about_font_style,
  about_font_weight,
  about_color,
  content_align,
  bg_color,
  heading_font_color,
  heading_font_family,
  heading_font_size,
  heading_font_weight,
}) => {
  return (
    <div className="single-post" style={{ backgroundColor: bg_color.color }}>
      <div className="row-box  flex-box-center ">
        <div className="colsm-2">
          <p
            className="center-title title-text"
            style={{
              fontFamily: heading_font_family.family,
              fontSize: heading_font_size.size,
              fontWeight: heading_font_weight.weight,
              color: heading_font_color.color,
            }}
          >
            {aboutTitle}
          </p>
        </div>
      </div>

      <div className="aboutcenter">
        <div className="widget-para">
          <ul className="ListStyle">
            {about_content.map((desc, index) => (
              <li
                className="liItem"
                style={{
                  listStyle: content_align,
                  fontFamily: about_font_family.family,
                  fontSize: about_font_style.size,
                  fontWeight: about_font_weight.weight,
                  color: about_color.color,
                }}
                key={index}
              >
                <p
                  style={{
                    listStyle: content_align,
                    fontFamily: about_font_family.family,
                    fontSize: about_font_style.size,
                    fontWeight: about_font_weight.weight,
                    color: about_color.color,
                  }}
                >
                  {' '}
                  {desc.content_desc}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Aboutlist
